import React from "react"
import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"
import { Navbar, Nav } from "react-bootstrap"
import Scroller from './scroller'

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this);
    console.log({props})
  }

  render() {
    return (
      <>
        <Navbar className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav" expand="lg"
          collapseOnSelect={true}>
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="/">
              <svg id="Logo" data-name="Logo" xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 372.68 169.19">
                <path className="logo" d="M75,10.56c.15-.46.31-.93.5-1.39a2,2,0,0,1,1-1,7.75,7.75,0,0,1,1.79-.6,14.12,14.12,0,0,1,3.13-.26c.59,0,1.38,0,2.35-.09L86.81,7c1.12-.06,2.26-.14,3.41-.26s2.22-.23,3.19-.35a22.55,22.55,0,0,1,.22,2.77c0,1.85-.37,2.83-1.12,2.94q-4,.87-5.36,2.34a5.57,5.57,0,0,0-1.34,3.89c0,.12,0,1.65.17,4.59s.28,7.84.5,14.71.5,16,.84,27.42.72,25.61,1.17,42.57a7.72,7.72,0,0,0,1.45,4.84c.9,1,2.72,1.91,5.48,2.6a1.4,1.4,0,0,1,.84,1,5.57,5.57,0,0,1,.28,1.82,22.92,22.92,0,0,1-.23,2.77c-2.6-.24-5-.44-7.09-.61s-4.08-.26-5.87-.26q-1.12,0-2.79.09L77,120c-1.23.06-2.45.14-3.64.26s-2.23.23-3.13.35a22.55,22.55,0,0,1-.22-2.77c0-1.85.37-2.77,1.12-2.77a18.41,18.41,0,0,0,6.09-2.77q1.62-1.38,1.62-4.67,0-.52-.17-6.4t-.45-14.37q-.27-8.48-.55-18.34t-.56-18.6q-.29-8.73-.51-15.05t-.22-7.7L51.94,118.7a13.82,13.82,0,0,1-.83,2.34,1.2,1.2,0,0,1-1.07.78,1.79,1.79,0,0,1-1-.18L21.66,24.4q-.12,1.73-.28,8.39t-.45,15.83Q20.64,57.8,20.32,68T19.7,86.86q-.27,8.65-.45,14.54t-.16,6.23q0,5.36,7.6,7.44c.74.12,1.11,1,1.11,2.77a22.55,22.55,0,0,1-.22,2.77c-2-.24-4-.44-5.92-.61s-3.65-.26-5.14-.26c-1.2,0-2.8.09-4.81.26s-3.91.37-5.7.61a22.55,22.55,0,0,1-.22-2.77c0-1.62.37-2.54,1.12-2.77a12.27,12.27,0,0,0,4.86-2.51,6.94,6.94,0,0,0,2.06-4.93q0-1.56.23-8.74t.5-17q.28-9.87.62-20.94t.61-20.51q.29-9.42.45-15.57c.11-4.09.16-6.2.16-6.31A5.3,5.3,0,0,0,15,14.45c-.93-.87-2.7-1.64-5.31-2.34C9,12,8.58,11,8.58,9.17A22.92,22.92,0,0,1,8.81,6.4c1,.12,2.06.24,3.29.35s2.46.2,3.69.26l3.52.17c1.12.06,2,.09,2.68.09a14.12,14.12,0,0,1,3.13.26,7.75,7.75,0,0,1,1.79.6,2,2,0,0,1,.95,1c.19.46.35.93.5,1.39L52.28,96.9Z" transform="translate(-5.79 -6.4)" />
                <path className="logo" d="M130.31,52.38H111.5L106.1,68.7a6.21,6.21,0,0,0-.43,2c0,1.42,1.72,2.44,5.15,3a1,1,0,0,1,.8.56,2.55,2.55,0,0,1,.21,1.07,9.66,9.66,0,0,1-.17,1.62c-1.51-.14-3.12-.26-4.8-.36s-3.35-.15-5-.15c-1.13,0-2.28,0-3.46.1a36.55,36.55,0,0,0-3.71.41,7.43,7.43,0,0,1-.17-1.62,3.17,3.17,0,0,1,.21-1.22,1,1,0,0,1,.88-.61,13,13,0,0,0,3.93-1.27,4.37,4.37,0,0,0,1.9-2.38l20.33-58.79c.39-1.15.93-1.72,1.6-1.72a3.64,3.64,0,0,1,1,.2l20.42,60.62a4,4,0,0,0,2.28,2.23,14.31,14.31,0,0,0,3.46,1.11,2,2,0,0,1,.8.56,2,2,0,0,1,.3,1.27,10.85,10.85,0,0,1-.17,1.62c-1.8-.14-3.57-.26-5.32-.36s-3.37-.15-4.89-.15-3.38,0-5.4.15-3.85.22-5.48.36a9.66,9.66,0,0,1-.17-1.62,2,2,0,0,1,.29-1.27.79.79,0,0,1,.55-.36,16.47,16.47,0,0,0,3.54-1.16,2.11,2.11,0,0,0,1.35-2,4.69,4.69,0,0,0-.33-1.52ZM129,48.22l-7.85-24.83-8.18,24.83Z" transform="translate(-5.79 -6.4)" />
                <path className="logo" d="M180.26,77.72a27.52,27.52,0,0,1-12.4-2.64A24.55,24.55,0,0,1,159,67.83,31.6,31.6,0,0,1,153.81,57a49.79,49.79,0,0,1-1.73-13.43,43.74,43.74,0,0,1,1.73-12.07,35.29,35.29,0,0,1,5.23-11,27.78,27.78,0,0,1,8.82-8,24.2,24.2,0,0,1,12.4-3.09c1.85,0,3.56,0,5.1.15a42.43,42.43,0,0,1,4.43.51,40.06,40.06,0,0,1,4.14.91c1.35.37,2.75.79,4.21,1.27q.09,2.73.6,6.69t1.35,8a2.64,2.64,0,0,1-1.35.41,3.65,3.65,0,0,1-1.23-.2A1.33,1.33,0,0,1,196.8,26q-1.53-6.69-4.81-9.53t-9.54-2.84a20.21,20.21,0,0,0-9.24,2,19.23,19.23,0,0,0-6.75,5.68,25.72,25.72,0,0,0-4.13,8.77,43.32,43.32,0,0,0-1.39,11.4,44.49,44.49,0,0,0,1.77,12.87,35,35,0,0,0,4.77,10.14,22.28,22.28,0,0,0,7,6.59A16.8,16.8,0,0,0,183,73.46a19,19,0,0,0,6.12-.86,10.73,10.73,0,0,0,4.13-2.49,14,14,0,0,0,2.79-4,48.59,48.59,0,0,0,2.15-5.42,2.06,2.06,0,0,1,2-1.52,2.86,2.86,0,0,1,.89.15,2.41,2.41,0,0,1,.72.35c-.68,2.37-1.35,5-2,7.81s-1.24,5.68-1.69,8.51c-2.75.54-5.56,1-8.43,1.27A91.17,91.17,0,0,1,180.26,77.72Z" transform="translate(-5.79 -6.4)" />
                <path className="logo" d="M222,43.76V69.41A3.37,3.37,0,0,0,223,72.24a11.44,11.44,0,0,0,3.93,1.52,1.25,1.25,0,0,1,.67.41,1.77,1.77,0,0,1,.25,1.11,11,11,0,0,1-.16,1.73c-1.8-.14-3.52-.26-5.15-.36s-3.15-.15-4.56-.15-3.1,0-4.93.15-3.59.22-5.27.36a9.66,9.66,0,0,1-.17-1.62c0-1,.28-1.49.84-1.63a16.36,16.36,0,0,0,4.39-1.57A3.06,3.06,0,0,0,214,69.41V17.81a3.37,3.37,0,0,0-1.18-2.89,11.94,11.94,0,0,0-4.39-1.47c-.56-.07-.84-.64-.84-1.72a9.46,9.46,0,0,1,.17-1.62c1.63.13,3.37.25,5.23.35s3.48.15,4.89.15,3.09,0,4.89-.15,3.6-.22,5.4-.35a10.6,10.6,0,0,1,.17,1.62c0,1-.28,1.59-.84,1.72a11.94,11.94,0,0,0-4.39,1.47A3.34,3.34,0,0,0,222,17.81V42.34L241.52,19a7.17,7.17,0,0,0,.89-1.37,3.52,3.52,0,0,0,.38-1.57,1.84,1.84,0,0,0-1-1.68,8.66,8.66,0,0,0-2.57-.86c-.57-.06-.85-.67-.85-1.82a10.6,10.6,0,0,1,.17-1.62c1.63.13,3.26.25,4.89.35s3.13.15,4.48.15,2.54,0,3.92-.15,2.63-.22,3.75-.35a9.46,9.46,0,0,1,.17,1.62q0,1.62-.93,1.92a15.09,15.09,0,0,0-3.46,1.22,11,11,0,0,0-3.12,2.53l-19,22.51,23,29.8a10.09,10.09,0,0,0,2.87,2.63,11.43,11.43,0,0,0,3.46,1.22c.68.07,1,.64,1,1.72a10,10,0,0,1-.17,1.73q-2.61-.21-5.73-.36t-5.4-.15q-2.37,0-5,.15c-1.74.1-3.57.22-5.48.36a9.66,9.66,0,0,1-.17-1.62c0-1,.3-1.56.92-1.63,2.54-.2,3.8-1.08,3.8-2.63a3.19,3.19,0,0,0-.84-2Z" transform="translate(-5.79 -6.4)" />
                <path className="logo" d="M298.86,52.38H280l-5.4,16.32a6.43,6.43,0,0,0-.42,2c0,1.42,1.72,2.44,5.15,3a1,1,0,0,1,.8.56,2.55,2.55,0,0,1,.21,1.07,10.85,10.85,0,0,1-.17,1.62c-1.52-.14-3.12-.26-4.81-.36s-3.35-.15-5-.15c-1.12,0-2.27,0-3.45.1a36.73,36.73,0,0,0-3.72.41,8.1,8.1,0,0,1-.17-1.62,3,3,0,0,1,.22-1.22,1,1,0,0,1,.88-.61,13,13,0,0,0,3.92-1.27,4.29,4.29,0,0,0,1.9-2.38l20.34-58.79c.39-1.15.92-1.72,1.6-1.72a3.7,3.7,0,0,1,1,.2l20.42,60.62a4,4,0,0,0,2.28,2.23,14.31,14.31,0,0,0,3.46,1.11,2.06,2.06,0,0,1,.8.56,2.1,2.1,0,0,1,.29,1.27A9.66,9.66,0,0,1,320,77q-2.7-.21-5.31-.36t-4.89-.15q-2.37,0-5.4.15t-5.49.36a10.85,10.85,0,0,1-.17-1.62,2,2,0,0,1,.3-1.27.77.77,0,0,1,.55-.36,16.47,16.47,0,0,0,3.54-1.16,2.11,2.11,0,0,0,1.35-2,4.49,4.49,0,0,0-.34-1.52Zm-1.35-4.16-7.85-24.83-8.18,24.83Z" transform="translate(-5.79 -6.4)" />
                <path className="logo" d="M340.36,69.91,321.7,18a9.68,9.68,0,0,0-2.8-3.9,12.1,12.1,0,0,0-4.58-2,1.43,1.43,0,0,1-.84-1A6.91,6.91,0,0,1,313.2,9V8.22a2.67,2.67,0,0,1,.11-.78,4.68,4.68,0,0,1,.12-1q2.44.36,6.26.61t7.37.26c.9,0,1.94,0,3.13-.09L333.88,7c1.26-.06,2.48-.14,3.63-.26s2.14-.23,3-.35a6,6,0,0,0,.11,1.21,5.22,5.22,0,0,0,.06.87,4,4,0,0,1,.06.69,6.32,6.32,0,0,1-.28,1.9,1.43,1.43,0,0,1-.84,1,12.52,12.52,0,0,0-4.53,1.65,3.15,3.15,0,0,0-1.28,2.68,9,9,0,0,0,.78,3.29l13.86,38.76,13.07-36a15.52,15.52,0,0,0,1.12-4.85,4.17,4.17,0,0,0-1.51-3.46,13.13,13.13,0,0,0-4.75-2.08q-1.23-.16-1.23-2.94a9.33,9.33,0,0,1,.12-1.56,7.21,7.21,0,0,1,.11-1.21q2.45.36,5.75.61t6.09.26c1.72,0,3.52-.09,5.42-.26s3.78-.37,5.65-.61a5.37,5.37,0,0,0,.11,1.21,5.22,5.22,0,0,0,.06.87,5.34,5.34,0,0,1,.05.69q0,2.43-1.23,2.94a14,14,0,0,0-5.53,2.17A9.75,9.75,0,0,0,369,18.69l-18.1,49.66v38.76q0,3.81,1.84,5.28a16.56,16.56,0,0,0,6.2,2.51q1.23.34,1.23,2.76c0,.24,0,.52-.05.87a7.77,7.77,0,0,0-.06.86,7.81,7.81,0,0,0-.11,1l-5.25-.34c-1.49-.12-3.08-.24-4.75-.35s-3.15-.17-4.42-.17-2.72.06-4.36.17-3.2.23-4.69.35l-5.14.34a3.45,3.45,0,0,1-.11-1,2.62,2.62,0,0,1-.12-.86v-.87c0-1.61.38-2.53,1.12-2.76a25.5,25.5,0,0,0,6.26-2.69q1.91-1.29,1.9-5.1Z" transform="translate(-5.79 -6.4)" />
                <rect className="logo" x="113.16" y="88.07" width="28.65" height="28.65" />
                <rect className="logo" x="183.71" y="88.07" width="28.65" height="28.65" />
                <rect className="logo" x="251.05" y="88.07" width="28.65" height="28.65" />
                <g>
                  <path className="logo" d="M12.51,141.6H24.12c10.5,0,17.76,7.21,17.76,16.62v.09c0,9.41-7.26,16.71-17.76,16.71H12.51Zm11.61,29.94c8.45,0,13.84-5.73,13.84-13.13v-.1c0-7.4-5.39-13.22-13.84-13.22H16.29v26.45Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M51.17,141.6H75.33V145H54.94v11.41H73.18v3.44H54.94v11.7H75.57V175H51.17Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M83.92,141.6h3.54l21.05,26.79V141.6h3.68V175h-3L87.6,147.62V175H83.92Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M131.24,145.09H120V141.6h26.26v3.49H135.06V175h-3.82Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M162,141.36h3.53L180.76,175H176.7l-3.91-8.83H154.6l-4,8.83h-3.86Zm9.31,21.44-7.6-17-7.63,17Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M188.39,141.6h3.77v29.94H211V175H188.39Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M234.32,158.41v-.1c0-9.45,7.06-17.28,16.9-17.28a17.39,17.39,0,0,1,13,5.3l-2.58,2.77c-2.81-2.67-6-4.58-10.5-4.58-7.4,0-12.94,6-12.94,13.7v.09c0,7.74,5.59,13.8,12.94,13.8,4.58,0,7.59-1.76,10.79-4.82l2.48,2.43c-3.48,3.54-7.3,5.88-13.37,5.88C241.43,175.6,234.32,168,234.32,158.41Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M284.89,141.36h3.54L303.66,175H299.6l-3.92-8.83H277.49l-4,8.83h-3.87Zm9.31,21.44-7.59-17-7.64,17Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M311.29,141.6h14.37c4.1,0,7.4,1.24,9.5,3.35a9.23,9.23,0,0,1,2.58,6.58v.1c0,5.54-3.82,8.78-9.07,9.74L338.93,175H334.3l-9.69-13h-9.55v13h-3.77Zm14,17.05c5,0,8.6-2.58,8.6-6.88v-.09c0-4.11-3.15-6.59-8.55-6.59H315.06v13.56Z" transform="translate(-5.79 -6.4)" />
                  <path className="logo" d="M347.21,141.6h24.16V145H351v11.41h18.24v3.44H351v11.7h20.63V175h-24.4Z" transform="translate(-5.79 -6.4)" />
                </g>
              </svg>
            </a>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive">
              <Nav className="navbar-nav ml-auto my-2 my-lg-0">
                <Scrollspy className="navbar-nav"
                  items={["about", "services", "portfolio", "contact"]}
                  currentClassName="active" rootEl={"#mainNav"} offset={-75}>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#about" onClick={Scroller.handleAnchorScroll}>About Us</Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#services" onClick={Scroller.handleAnchorScroll}>Procedures</Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#portfolio" onClick={Scroller.handleAnchorScroll}>Our Practice</Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/implants">Dental Implants</Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#contact" onClick={Scroller.handleAnchorScroll}>Contact</Nav.Link>
                  </li>
                </Scrollspy>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-100 align-items-left justify-content-left text-left">
              <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-end">
                <h1 className="text-uppercase text-white font-weight-bold">Providing you the best dental care for more than 15 years</h1>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-8 col-8 align-self-baseline">
                <p className="text-white-75 font-weight-light mb-5">&ldquo;This community is important to me. I enjoy serving and helping our community improve their oral health.  It’s about helping others have a healthy smile. <strong>Everyone should have a healthy smile.</strong> &rdquo;<br /> &mdash;<em>Matt MacKay</em></p>
                
                <a className="btn btn-primary btn-xl js-scroll-trigger" href="#testimonials" onClick={Scroller.handleAnchorScroll}>Read what our patients are saying</a>
              </div>
            </div>
          </div>
        </header>
        {/* <div className="covid19-banner">
          <div className="container"><Link to="/covid19">Important information regarding COVID-19 (Coronavirus)</Link></div>
        </div> */}
      </>
    );
  }
}
