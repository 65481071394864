/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import SubHeader from "./subheader"
import "./layout.scss"

const getScrollNode = (element) => {
  return element.ownerDocument.scrollingElement || element.ownerDocument.documentElement
}

const isScrolled = (element) => {
  const scrollNode = getScrollNode(element)
  return scrollNode.scrollTop > 0
}

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.siteContainer = React.createRef()
    this.state = {
      scrolled: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  render() {
    let className = "site-container"
    if (this.props.className) className += ` ${this.props.className}`
    if (this.state.scrolled) className += " navbar-scrolled"

    return (
      <div
        className={className}
        ref={this.siteContainer}
        id="page-top">
        <Header/>
        <main>{this.props.children}</main>
        <footer className="bg-light py-5">
          <div className="container">
            <div className="small text-center text-muted">Copyright &copy; 2019 - MacKay Dental Care</div>
          </div>
        </footer>
        <div id='mobileCTA' className='section section--mobileCTA visible-xs'>
          <div className='flex mobile-cta-container justify-around'>
            <a className='mobile-cta-link flex-100 preserve-styles' href='tel:18013972323' data-track-event='call'><strong className='mobile-cta-content'><i className='fa fa-phone' aria-hidden='true' /><span>Call</span></strong></a>
            <a className='mobile-cta-link flex-100' href="#map"><strong className='mobile-cta-content'><i className='fa fa-map-marker' aria-hidden='true' /><span>Map</span></strong></a>
            <a className='mobile-cta-link flex-100 external-link' href='#'><strong className='mobile-cta-content'><i className='fa fa-star' aria-hidden='true' /><span>Rate</span></strong></a>
          </div>
        </div>
      </div>
    )
  }
}


export class SubpageLayout extends React.Component {
  constructor(props) {
    super(props)
    this.siteContainer = React.createRef()
    this.state = {
    }
  }

  render() {
    return (
      <div>
        <SubHeader/>
        <header className="masthead">
          
              <main>{this.props.children}</main>

        </header>
        <footer className="bg-light py-5">
          <div className="container">
            <div className="small text-center text-muted">Copyright &copy; 2019 - MacKay Dental Care</div>
          </div>
        </footer>
        <div id='mobileCTA' className='section section--mobileCTA visible-xs'>
          <div className='flex mobile-cta-container justify-around'>
            <a className='mobile-cta-link flex-100 preserve-styles' href='tel:18013972323' data-track-event='call'><strong className='mobile-cta-content'><i className='fa fa-phone' aria-hidden='true' /><span>Call</span></strong></a>
            <a className='mobile-cta-link flex-100' href="#map"><strong className='mobile-cta-content'><i className='fa fa-map-marker' aria-hidden='true' /><span>Map</span></strong></a>
            <a className='mobile-cta-link flex-100 external-link' href='https://www.google.com/search?source=hp&ei=ufAQXqbKKNjn-wSe77mQAg&q=mackaydentalcare&oq=mackaydentalcare&gs_l=psy-ab.3..0i13j0i13i30l2j0i8i13i30l7.1310.4264..5230...1.0..0.110.1618.12j5......0....1..gws-wiz.......0j0i131j0i10.Hu4VQ0kfrL4&ved=0ahUKEwjmirTp3-rmAhXY854KHZ53DiIQ4dUDCAg&uact=5#lrd=0x8752f8213b8da61b:0xb1673136a11e2f89,3,,,'><strong className='mobile-cta-content'><i className='fa fa-star' aria-hidden='true' /><span>Rate</span></strong></a>
          </div>
        </div>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}